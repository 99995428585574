@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth !important;
}

body {
  margin: 0;
  font-family: 'Montserrat','myriad-pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul li a.active {
  border-bottom: 2px solid black;
}
.back-to-top{
  display: none;
}
@media (min-width: 1024px) {
  ul li a.active {
    border-bottom: 2px solid white;
  }
}
.moving-bg-demo{
  background-image: url("../public/Assets/dallas_cover.jpg");
  background-repeat: no-repeat;
  
  overflow: hidden;
}
.text-shadow {
  text-shadow: 1px 1px 3px rgb(0 0 0 / 29%), 2px 4px 7px rgb(73 64 125 / 35%)
}

.word-spacing {
  word-spacing: 5px;
}

.font-Radio-Canada {
  font-family: 'Radio Canada', sans-serif;
}

.thumb,
.selected {
  border-radius: 10px !important;
}

@media (min-width: 1024px) {
  .background {
    background-image: url("../public/Assets/Features/feature.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.mainbg {
  background-image: url("../public/Assets/Library/Asset 59.png");
  background-color: rgb(21, 37, 53);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 34% 0% */
}

.na {
  position: absolute;
  top: 48%;
  left: 22%;
  height: 90px;
}

.asia {
  position: absolute;
  top: 48%;
  left: 68%;
  height: 90px;
}

.eur {
  position: absolute;
  top: 42%;
  left: 46%;
  height: 90px;
}

.me {
  position: absolute;
  top: 53%;
  left: 52%;
  height: 90px;
}
.over-cities1{

  position: absolute;

  top: 32% !important;

  left: 80% !important;

  width: 191px !important;

}

.over-cities2{

  position: absolute;

  top: 32% !important;

  left: 80% !important;

  width: 191px !important;

}

.over-cities3{

  position: absolute;

  top: 32% !important;

  left: 80% !important;

  width: 191px !important;

}

.over-cities4{

  position: absolute;

  top: 32% !important;

  left: 80% !important;

  width: 191px !important;

}

.footerbg{
  background: rgb(21, 37, 53);
  padding: 80px 0 10px 0;
  /* background-image: url(../public/Assets/Library/footerbg.png); */
  background-size: 950px 280px;
  background-repeat: no-repeat;
}